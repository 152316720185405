import React, { useState, useEffect, useMemo } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import { debounce } from "@mui/material/utils";
import {
  MaterialReactTable,
  useMaterialReactTable
} from "material-react-table";
import Tooltip from '@mui/material/Tooltip';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { fetchUserList } from "../../api/users";
import {  Stack } from "@mui/material";

export default function AddProjectUserTable(props) {
  const { data, setData,excludeIds } = props;
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const resetState = () => {
    setLoading(false);
    setOptions([]);
    setInputValue("");
  }

  useEffect( ()=> {
    return () => { resetState()}
  },[])

  const fetch = useMemo(
    () =>
      debounce((input, callback) => {
        setLoading(true);
        fetchUserList(input,20).then((res) => {
          if (callback) {   
            callback(res?.data || []);
            setLoading(false);
          }
        });
      }, 400),
    []
  );

  const handleItemClick = (selectedItem) => {
      setData((prevData) => [
        ...prevData,
        {
          _id: selectedItem._id,
          name: selectedItem.name,
          email: selectedItem.email
        }
      ]);
    setInputValue("");
  };

  const handleDeleteItemClick = (userId) => {
    setData((prevData) => prevData.filter((item) => item._id !== userId));
  };

  const handleAddRole = (role, userId) => {
    if (role && role.value) {
      setData((prevData) => {
        const newData = prevData.map((user) => {
          if (user._id === userId) {
            return { ...user, role: role.value };
          }
          return user;
        });
        return newData;
      });
    }
  };
  
  const roles = [ {
    id:1,
    name:"Admin",
    value:"admin"
  },{
    id:2,
    name:"Power User",
    value:"poweruser"
  },{
    id:3,
    name:"User",
    value:"user"
  },
]
  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        Cell: (data) => (
          <Typography variant="body1">{data.renderedCellValue}</Typography>
        ),
      },
      {
        accessorKey: "email",
        header: "Email",
        Cell: (data) => (
          <Typography variant="body1">{data.renderedCellValue}</Typography>
        ),
      },
      {
        accessorKey: "role",
        header: "Role",
        Cell: (data) => (
          <Autocomplete
            options={roles}
            disableClearable
            onChange={(event, value) => {
              handleAddRole(value, data.row.id);
            }}
            renderOption={(props, option) => (
              <li {...props}>
                {option.name}
              </li>
            )}
            defaultValue={roles.filter(r => r.value === data.renderedCellValue).map(x => x.name)?.[0] || "User"}
            getOptionLabel={(option) => option.name || option}
            renderInput={(params) => <TextField {...params} label="Role" />}
          />
        ),
      },
      {
        header: "Actions",
        Cell: (data) => (
          <Tooltip title="Remove User">
            <RemoveCircleIcon
              sx={{ color: "error.main", fontSize: { xs: "medium", sm: "large" } }}
              onClick={() => handleDeleteItemClick(data.row.id)}
            />
          </Tooltip>
        ),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    data,
    columns,
    enableTopToolbar: false,
    enableColumnActions: false,
    enableBottomToolbar: false,
    enableSorting: false,
    enableStickyHeader:true,
    muiTableHeadCellProps: {
      sx: {
        fontWeight: 600,
        fontSize: 18,
      },
    },
    getRowId: (row) => row._id,
  });


  useEffect(() => {
    let active = true;

    if (inputValue === "") {
      setOptions([]);
      return () => {
        active = false;
      };
    }

    fetch(inputValue, async (results) => {
      if (active) {
        let ids = await data.map(x => x._id) 
        if(excludeIds) {
            ids = [...ids,...excludeIds];
        }
        console.log(ids);
        results = results.filter(x => !ids.includes(x._id))
        console.log(results)
        setOptions(results || []);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  return (
    <Stack alignContent={"center"} gap={2}>
      <Autocomplete
        userId="search-users"
        fullWidth
        freeSolo
        getOptionLabel={(option) => option.displayName || option}
        filterOptions={(x) => x}
        options={options}
        value={inputValue}
        autoComplete
        loading={loading}
        onInputChange={handleInputChange}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search Users"
            fullWidth
          />
        )}
        renderOption={(props, option) => (
          <li {...props} onClick={() => handleItemClick(option)}>
            {option.name} - {option.email}
          </li>
        )}
      />
      <MaterialReactTable table={table} />
    </Stack>
  );
}