import webApiService from "../utils/axiosClient";
import { responseConstants } from "../utils/constants";
const BASE_URL = "/customuser";

export const validateUser = async (userData) => {
    try {
        const response = await webApiService.post(`${BASE_URL}/`,userData);
       
        let status = response?.data?.code;
        let data = response?.data || {};
        if(status == responseConstants.OK) {
          return {data:data.data,success: true}
        } else {          
          return { success: false,error: data?.message, code: status}
        }
    } catch (error) {
        throw error;
    }
};