import React, { useState, useEffect, useCallback } from "react";
import { Container, Box, Divider, Typography, Button, TextField } from "@mui/material";
import MicrosoftLogin from "../../components/MicrosoftLogIn";
import { useSelector } from "react-redux";
import { useNavigate,useLocation } from "react-router-dom";
import { useDispatch  } from "react-redux";
import { setCurrentUser } from "../../app/userSlice";
import styles from "./styles.module.css";
import { validateUser } from "../../api/customuser";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
function SignIn() {
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [customEmail, setCustomEmail]  = useState('');
  const [customPassword, setCustomPassword] = useState('');
  const [customUserInvalid, setCustomUserInvalid] = useState(false);
  const [customPasswordError, setCustomPasswordError] = useState(false);
  const [customEmailError, setCustomEmailError] = useState(false);
  const queryParams = useQuery();
  const requestaccess = queryParams.get('requestAccess') || false;

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/home");
    }
  }, []);

  const prompts = [
    "How do I automate my INDs",
    "What are the Biological Pathways for covid",
    "What is the mechanism of action for penicillin",
    "Draft an email to FDA",
  ];

  const typingSpeed = 100; // adjust speed as needed

  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentPrompt, setCurrentPrompt] = useState("");
  const [isTyping, setIsTyping] = useState(true);
  const [isWaiting, setIsWaiting] = useState(false);

  const handleTyping = useCallback(() => {
    if (isWaiting) return;

    if (isTyping) {
      if (currentPrompt.length < prompts[currentIndex].length) {
        setCurrentPrompt((prev) =>
          prompts[currentIndex].slice(0, prev.length + 1)
        );
      } else {
        setIsTyping(false);
        setIsWaiting(true);

        setTimeout(() => {
          setIsWaiting(false);
        }, 2000); // wait for 2 second
      }
    } else {
      if (currentPrompt.length > 0) {
        setCurrentPrompt((prev) => prev.slice(0, -1));
      } else {
        setIsTyping(true);
        setCurrentIndex((prevIndex) => (prevIndex + 1) % prompts.length);
      }
    }
  }, [currentPrompt, isTyping, currentIndex, isWaiting]);

  useEffect(() => {
    const typingInterval = setInterval(handleTyping, typingSpeed);
    return () => {
      clearInterval(typingInterval);
    };
  }, [handleTyping]);


  const handleRequestAccess = () => {
    const email = process.env.REACT_APP_ADMIN_EMAIL;
    const body = "I kindly request access to Prompt Manager.\nThank you for your assistance.";
    const subject = encodeURIComponent("Access Request for Prompt Manager");
    window.location.href = `mailto:${email}?subject=${subject}&body=${encodeURIComponent(body)}`;
  };

  const handleCustomLogin = async (email, password) => {
    setCustomEmailError(false);
    setCustomPasswordError(false);
    if(email.trim().length == 0){
      setCustomEmailError(true);
    }
    else if(password.trim().length == 0){
      setCustomPasswordError(true);
    }
    else{
      let userData = {
        'email': email,
        'password': password
      }
      let result = await validateUser(userData);
      if(result.code === 201){
        setCustomUserInvalid(true);
      }
      else{
        await dispatch(setCurrentUser(result.data));
        navigate("/home");
      }
    }
  };

  return (
    <Container
      component="main"
      maxWidth="lg"
      id={styles.container}
      style={{ display: "flex", alignItems: "center", height: "80vh" }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        height="70%"
      >
        <Box
          flex="0.9"
          sx={{
            paddingRight: "3rem",
            display: "flex",
            alignItems: "center", // This will center the text vertically
          }}
        >
          <Typography
            variant="h4"
            className={styles.typewriterText}
            sx={{
              width: "90%",
              color: "rgba(39, 110, 138, 1)",
              fontWeight: "700 !important",
              lineHeight: "2 !important",
            }}
          >
            {currentPrompt}
          </Typography>
        </Box>

        <Divider
          orientation="vertical"
          flexItem
          sx={{
            backgroundColor: "black",
            width: "6px",
            borderRadius: "5px",
          }}
        />

        <Box flex="1.1" pl={2} mt={12}>
          <Box mt={1} mb={3} sx={{ textAlign: "center", mb: 4 }}>
            <img
              src="/userprofile.png" // Replace with your path
              alt="User Profile"
              style={{ width: "30%", height: "30%", borderRadius: "50%" }} // Example size
            />
          </Box>
           {requestaccess &&          

              <Box flex="1.1" pl={2} mt={2} textAlign="center">
                          <Typography variant="h6" color="error" gutterBottom>
                You are not authorized to access the application.
              </Typography>
              <div>
                <Button
                  id="customBtn"
                  className={styles.request_button}
                  onClick={handleRequestAccess}
                  variant="contained"
                >
                  <div className={styles.request_text}><Typography sx={{ fontSize: "18px" }}>Request Access</Typography></div>
                </Button>
              </div>
            </Box>
           }
          <MicrosoftLogin />
        </Box>
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            backgroundColor: "black",
            width: "6px",
            borderRadius: "5px",
          }}
        />
        <Box
          flex="0.9"
          sx={{
            display: "flex",
            alignItems: "center",
            ml: 3
          }}
        >
          <Box flex="1.1" pl={2} >
              <Box sx={{ textAlign: "center", mb: 4 }}>
                  <img
                  src="/userprofile.png"
                  alt="User Profile"
                  style={{ width: "30%", height: "30%",  }}
                  />
              </Box>
                <Box display="flex" flexDirection="column" gap={2} alignItems="center" justifyContent="center" sx={{ width: '100%', maxWidth: '400px', margin: '0 auto' }}>
                <TextField
                  error={customEmailError} 
                  id="custom-login-email" 
                  label="Email" 
                  variant="standard" 
                  sx={{ width: '100%' }}
                  helperText= {customEmailError ? "Cannot be empty." : ""}
                  onChange={(e) => { setCustomEmail(e.target.value)}}
                />
              <TextField 
                error={customPasswordError}
                id="custom-login-password" 
                label="Password" 
                type="password"
                variant="standard" 
                sx={{ width: '100%' }}
                helperText={customPasswordError ? "Cannot be empty." : ""}
                onChange={(e) => { setCustomPassword(e.target.value)}}
              />
              <Button 
                variant="outlined"
                sx={{
                  fontSize: 18, 
                  borderRadius: "20px", 
                  transition: "background-color 0.3s ease",
                  borderColor: "rgba(224, 224, 224, 255)",
                  color: "rgba(162, 162, 162, 255)",
                  width: "60%",
                  minWidth: "150px",
                  '&:hover': {
                    backgroundColor: '#f0f2f5',
                    borderColor: 'rgba(224, 224, 224, 255)'
                  },
                }}
                onClick={() => handleCustomLogin(customEmail, customPassword)}
              >
                Login
              </Button>
              {customUserInvalid && <Typography variant="h6" color="error" gutterBottom>
                Invalid email/password !
              </Typography>}
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default SignIn;
